import { Link, Outlet, json, useLoaderData } from 'remix';
import type { LoaderFunction } from 'remix';
import { SDK } from '~/utils/sdk.server';

type Profile = {
  pagename: string;
};

type LoaderData = { profile: Profile };
export const loader: LoaderFunction = async ({ context, request, params }) => {
  const { pagename } = params
  const { env } = context
  const { API_HOST } = env;
  const profile = await new SDK({ API_HOST }).getPublicProfile({ pagename });
  if (!profile) {
    throw new Response("Not Found", {
      status: 404,
    });
  }
  return json<LoaderData>({
    profile
  });
};

export default function PublicProfile() {
  const { profile } = useLoaderData();
  return (
    <div>
      <Outlet />
    </div>
  );
}